import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import useOnScreen from "../../hooks/useOnScreen";

import "./block.scss";

const Block = ({
  centered,
  children,
  className,
  form,
  hero,
  stacked,
  tinted,
  dark,
  lazy,
}) => {
  const [winHeight, setWinHeight] = useState(undefined);
  const [isHidden, setIsHidden] = useState(lazy);
  const ref = useRef();

  /**
   *
   * Hook that fires when a block is on screen for the first time
   * or just became off screen.
   *
   */
  const onScreen = useOnScreen(
    ref,
    winHeight ? `-${winHeight / 2}px` : undefined
  );

  /**
   * When hooks fires and the block is not hidden, unhide it.
   */
  if (lazy && isHidden && onScreen) {
    setIsHidden(false);
  }

  /**
   * Set window height when component mounts.
   */
  useEffect(() => {
    if (window) {
      setWinHeight(window.innerHeight);
    }
  }, []);

  return (
    <div
      className={classnames("partie-block", {
        [className]: className,
        "partie-block--centered": centered,
        "partie-block--hero": hero,
        "partie-block--stacked": stacked,
        "partie-block--tinted": tinted,
        "partie-block--form": form,
        "partie-block--dark": dark,
        "partie-block--lazy": lazy,
      })}
      ref={ref}
      data-hidden={isHidden}
    >
      <div
        className={classnames("partie-block__content", {
          "partie-block__content--centered": centered,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Block;
